import { LinearProgress } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import SignupDialog from "../components/signup/signup-dialog/SignupDialog";
import { PaymentTypes } from "../constants/payment-types";
import DefaultLayout from "../layouts/default-layout";
import useStyles from "../styles/index/styles";

function Index() {
  const classes = useStyles();
  const [pageLoaded, setPageLoaded] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setPageLoaded(true);
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  }, []);

  if (!pageLoaded) return null;

  return (
    <DefaultLayout>
      <div className={classes.index} id="home">
        <Helmet>
          <title>Smart Baby Box</title>
        </Helmet>
        {isLoading ? <LinearProgress color="secondary" /> : null}
        <SignupDialog
          selectedMonth={1}
          selectedPaymentType={PaymentTypes.RECURRENCE}
          termsAndConditionsAccepted={false}
        />
      </div>
    </DefaultLayout>
  );
}

export default Index;
